<template>
    <div class="deposit">
        <div id="header">
            <div>
                <router-link class="header-items" v-if="accessToken" :to="{name:'RequestHistory'}">
                    <b-icon class="text-dark" icon="chevron-left" font-scale="2"></b-icon>
                </router-link>
            </div>
            <div class="header-items text-center">
                <img :src="require('../assets/images/horizontal_logo.png')" width="200" height="44">
            </div>
            <div></div>
        </div>

        <div class="d-flex justify-content-center align-items-center h-100" v-if="isLoading">
            <b-spinner variant="success" label="Spinning"></b-spinner>
        </div>

        <div class="d-flex flex-column align-items-center mt-3" v-if="!isLoading 
            && transakAvailable
            && (this.depositDetail.status == 'pending' || this.depositDetail.status == 'unmatched') 
            && this.depositDetail.countdown">
            <div>
                <button class="btn-center" @click="popUpTransak()">Buy Crypto</button>
            </div>
        </div>

        <div v-if="!isLoading" id="depositDetail">
            <div class="address-mobile" v-if="(this.depositDetail.status == 'pending' || this.depositDetail.status == 'unmatched') && this.depositDetail.countdown">
                <div class="address-box">
                    <div class="mr-2">{{ depositDetail.address }}</div>
                    <div class="tooltip__layout">
                        <button class="icon-btn" @click="copyToClip(depositDetail.address)">
                            <inline-svg
                            :src="require('../assets/icon/copy.svg')"
                            fill="black"
                            width="18"
                            aria-label="copy"
                            ></inline-svg>
                            <span class="tooltip__content" :id="depositDetail.address">Copied!</span>
                        </button>
                    </div>
                </div>
            </div>

            <div class="payment-box" v-bind:class="{ expired : this.depositDetail.status == 'unused' || (this.depositDetail.status == 'unmatched' && !this.depositDetail.countdown)}">
                <div class="img-box">
                    <div class="" v-if="(this.depositDetail.status == 'pending' || this.depositDetail.status == 'unmatched') && this.depositDetail.countdown">
                        <vue-qr :text="depositDetail.address" :size="200"></vue-qr>
                    </div>

                    <div class="text-center" v-if="depositDetail.status == 'deposited'">
                        <inline-svg
                            :src="require('../assets/icon/checked.svg')"
                            fill="black"
                            height="200"
                            width="200"
                            aria-label="copy"
                        ></inline-svg>
                    </div>

                    <div class="text-center" v-if="this.depositDetail.status == 'unused' || (this.depositDetail.status == 'unmatched' && !this.depositDetail.countdown)">
                        <inline-svg
                            :src="require('../assets/icon/cross.svg')"
                            fill="black"
                            height="200"
                            width="200"
                            aria-label="copy"
                        ></inline-svg>
                    </div>

                </div>

                <div class="payment-box-right">

                    <div class="address-web mb-3" v-if="(this.depositDetail.status == 'pending' || this.depositDetail.status == 'unmatched') && this.depositDetail.countdown">
                        <div class="address-box">
                            <div class="mr-2">{{ depositDetail.address }}</div>
                            <div class="tooltip__layout">
                                <button class="icon-btn" @click="copyToClip(depositDetail.address)">
                                    <inline-svg
                                    :src="require('../assets/icon/copy.svg')"
                                    fill="black"
                                    width="18"
                                    aria-label="copy"
                                    ></inline-svg>
                                    <span class="tooltip__content" :id="depositDetail.address">Copied!</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="address-box expire-text" v-if="this.depositDetail.status == 'unused' || (this.depositDetail.status == 'unmatched' && !this.depositDetail.countdown)">
                        Transaction expired
                    </div>

                    <div class="deposit-text mb-1 mt-2" >
                        <div class="">Deposit ID: </div>
                        <div class="deposit-id px-1">{{ deposit_id }}</div>
                    </div>

                    <div class="deposit-text text-center mt-1">
                        <div class="amount-text mr-2">
                            {{ formatNum(depositDetail.amount, depositDetail.token.volume_decimal) }}
                        </div>
                        <div class="symbol-text">
                            {{ depositDetail.token.symbol }}
                        </div>
                    </div>

                    <div class="network-text align-text text-center mt-2">
                        ( Network: {{ network.toUpperCase() }} )
                    </div>

                    <h1 class="success-text" v-if="depositDetail.status == 'deposited'">Payment Sucessfully Received!</h1> 

                </div>

                <!-- <div class="disclaimer-mobile mt-2" v-if="this.depositDetail.status == 'pending' || (this.depositDetail.status == 'unmatched' && this.depositDetail.countdown)">
                    <div class="disclaimer-text">
                        *Please send the exact amount within the timeframe. Not doing so will result in a delay of payment, or refund up to 7 working days.
                    </div>
                    <div class="disclaimer-text">
                        **Please complete the transfer within the countdown time indicated to ensure the current rate and success of the payment. If the funds are transferred after the time lapsed the payment will automatically cancel and require refreshing or redirected back to merchant page to restart the payment process.
                    </div>
                </div> -->

            </div> 

            <div v-if="(this.depositDetail.status == 'pending' || this.depositDetail.status == 'unmatched') && this.depositDetail.countdown">
                <div class="countdown-text">
                    You have <span class="countdown-timer">{{countdownMinutes}}:{{countdownSeconds}}</span> minutes to complete this payment. 
                </div>
            </div>

            <div class="pending-box" v-if="(this.depositDetail.status == 'pending' || this.depositDetail.status == 'unmatched') && this.depositDetail.countdown">
                Awaiting Payment From You
            </div>

            <div class="text-center" v-if="(this.depositDetail.status == 'pending' || this.depositDetail.status == 'unmatched') && this.depositDetail.countdown">
                <div class="disclaimer-text">
                    *Please send the exact amount within the timeframe. Not doing so will result in a delay of payment, or refund up to 7 working days.
                </div>
                <div class="disclaimer-text">
                    **Please complete the transfer within the countdown time indicated to ensure the current rate and success of the payment. If the funds are transferred after the time lapsed the payment will automatically cancel and require refreshing or redirected back to merchant page to restart the payment process.
                </div>
            </div>

            <div class="text-center" v-if="this.depositDetail.status == 'unused' || (this.depositDetail.status == 'unmatched' && !this.depositDetail.countdown)">
                <div class="disclaimer-text">
                    *Deposit time has EXPIRED, DO NOT DEPOSIT INTO THIS ADDRESS ANYMORE. Doing so will result in a delay of payment, or refund up to 7 working days.
                </div>
                <div class="disclaimer-text">
                    **If funds are transferred after the time lapsed, the payment will automatically cancel and require refreshing or redirected back to merchant page to restart the payment process.
                </div>
            </div>

            <div class="text-center" v-if="depositDetail.status == 'deposited'">
                Received on {{ convertDate(depositDetail.token.updated_at) }}
            </div>

            <div class="success-box" v-if="depositDetail.status == 'deposited'" @click="toExplorer()">
                View Transaction Details
            </div>

        </div>
    </div>
</template>

<script>
import VueQr from 'vue-qr'
import InlineSvg from "vue-inline-svg";
import moment from 'moment';
import api from '../helper/api.js';
import explorer from '../helper/explorer.js';
import Swal from 'sweetalert2';
import coinHelper from "../helper/coin.js";
import axios  from "axios"
import transakSDK from '@transak/transak-sdk'

export default {
    name:"DepositDetail",
    components: {
        InlineSvg,
        VueQr
    },

    data() {
        return {
            address: "0x86f11fd1Baaa57fED923dB5EbE38e0D0B06e3E97",
            deposit_id: this.$route.params.id,
            amount:0,
            countdownMinutes:1,
            countdownSeconds:1,
            // symbol:this.$route.params.symbol,
            depositId:this.$route.params.id,
            platformId:this.$route.params.platformId,
            network:this.$route.params.network,
            depositDetail:null,
            // depositDetail: { 
            //     status: 'pending',
            //     deposit_id: 'ABC',
            //     network: 'TRX',
            //     address: {
            //         address:'TPfyrHUKs7ESr8m1JaQskB6SL3jnnAaiw7',
            //     },
            //     amount: 123,
            //     token: {
            //         symbol: 'DIA'
            //     },
            //     start: '2022-11-23 11:19:59',
            //     end: '2022-11-23 16:09:59',
            //     countdown: 100,
            //     redirect_url: 'https://www.google.com/',
            // },
            isLoading: true,
            accessToken:localStorage.getItem('token'),
            environment: process.env.VUE_APP_TRANSAK_ENVIRONMENT,
            apiKey: process.env.VUE_APP_TRANSAK_API_KEY,
            transakAvailable: false,
            networkList: {
                'trx': 'tron',
                'eth': 'ethereum',
                'bsc': 'bsc',
            }
        }
    },

    async mounted() {
        await this.fetchDepositDetail();
        await this.checkTransak();
        this.isLoading = false;

        if(this.depositDetail.status == 'deposited'){
            this.paymentDone();
            return;
        }
        if(this.depositDetail.status == 'unused' || (this.depositDetail.status == 'unmatched' && !this.depositDetail.countdown)){
            this.paymentExpired();
            return
        }

        let countdown = setInterval(()=>{
            let now = moment().format("x");
            let expire_date = moment(this.depositDetail.end).format('x');

            let timeLeft = expire_date - now*1;

            if(timeLeft <= 0){
                clearInterval(countdown);
            }

            let minutesLeft = moment.duration(timeLeft).minutes();
            this.countdownMinutes =
                minutesLeft.toString().length > 1
                ? minutesLeft
                : "0" + minutesLeft
            let secondsLeft = moment.duration(timeLeft).seconds();
            this.countdownSeconds = 
                secondsLeft.toString().length > 1 
                ? secondsLeft 
                : "0" + secondsLeft
        },1000);

        if(window.Worker){
            const worker = new Worker('/script/worker.js');
            worker.postMessage('');

            worker.onmessage = async()=>{
                try{
                    const response = await api.apiCall(this.network,'GET',`/deposit-request/payment/${this.platformId}/${this.depositId}`);
                    this.depositDetail = response.message.data;

                    if(this.depositDetail.status == 'unused' || (this.depositDetail.status == 'unmatched' && !this.depositDetail.countdown)){
                        this.paymentExpired();
                        worker.terminate();
                        clearInterval(countdown);
                    }
                    if(this.depositDetail.status == 'deposited'){
                        this.paymentDone();
                        worker.terminate();
                        clearInterval(countdown);
                    }
                }catch(error){
                    console.error(error);
                }
            }
        }
    },

    methods: {
        async fetchDepositDetail(){
            try{
                const response = await api.apiCall(this.network,'GET',`/deposit-request/payment/${this.platformId}/${this.depositId}`);
                this.depositDetail = response.message.data;
                // console.log(this.depositDetail)
            }catch(error){
                console.error(error);
                Swal.fire({
                    text: error.message,
                    icon: 'error',
                })
                // this.$router.back()
            }
        },

        async copyToClip(address) {
            await this.$copyText(address).then(function () {
                var x = document.getElementById(address);
                x.classList.add("show");
                setTimeout(function () {
                    x.classList.remove("show");
                }, 1000);
            })
        },

        formatNum(num, decimal){
            return coinHelper.formatNumberWithCommas(num, decimal);
        },

        convertDate(date){
            return moment(date).format('HH:mm DD-MM-YYYY')
        },

        toExplorer(){
            console.log(this.network, this.depositDetail.hash)
            let url = explorer.explorerLink(this.network, 'tx', this.depositDetail.hash)
            window.open(url, '_blank').focus();
        },

        paymentDone(){
            let timerInterval
            Swal.fire({
                title: 'Payment Completed!',
                html: 'You will be redirected to site in <b></b> seconds.',
                timer: 10500,
                timerProgressBar: true,
                allowOutsideClick: () => !Swal.isLoading(),
                didOpen: () => {
                    Swal.showLoading()
                    const b = Swal.getHtmlContainer().querySelector('b')
                    timerInterval = setInterval(() => {
                        b.textContent = Math.round(Swal.getTimerLeft() / 1000)
                    }, 900)
                },
                willClose: () => {
                    clearInterval(timerInterval)
                },
            }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                    console.log('I was closed by the timer')
                    window.open(this.depositDetail.redirect_url, '_blank').focus();
                    // window.location.href = this.depositDetail.redirect_url;
                }
            })
        },

        paymentExpired (){
            Swal.fire({
                text: 'Payment has expired, please request to deposit again if you wish to retry payment',
                icon: 'error',
            })
        },

        async checkTransak(){
            try {
                const config = {
                    headers: {
                        accept: 'application/json',
                    },
                    params: {
                        partnerApiKey: this.apiKey,
                        fiatCurrency: 'MYR',
                        cryptoCurrency: this.depositDetail.token.symbol,
                        isBuyOrSell: 'BUY',
                        network: this.networkList[(this.depositDetail.network).toLowerCase()],
                        paymentMethod: 'credit_debit_card',
                        // cryptoAmount: this.depositDetail.amount
                        fiatAmount: 500
                    }
                }
                await axios.get('https://api.transak.com/api/v2/currencies/price', config)

                this.transakAvailable = true

            } catch (error) {
                console.log(error);
                this.transakAvailable = false
            }

        },

        async popUpTransak() {
            let transak;

            if(this.depositDetail.token.price * this.depositDetail.amount > 29){
                transak = await this.transakFixed()
            } else {
                transak = await this.transakManual()
            }

            transak.init();

            // This will trigger when the user marks payment is made.
            transak.on(transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, () => {
                transak.close();
                Swal.fire({
                    text: "Crypto bought successfully",
                    icon: 'success',
                });
            });
            transak.on(transak.EVENTS.TRANSAK_WIDGET_CLOSE, () => {
                // alert('noooo');
                // transak.close();
            });
        },

        async transakFixed() {
            return new transakSDK({
                apiKey: this.apiKey,  // Your API Key
                environment: this.environment, // STAGING/PRODUCTION
                themeColor: '2575fc', // App theme color

                // payments //
                cryptoCurrencyCode: this.depositDetail.token.symbol,
                network: this.networkList[(this.depositDetail.network).toLowerCase()],
                defaultCryptoAmount: (this.depositDetail.amount).toString(),
                walletAddress: this.depositDetail.address,
                disableWalletAddressForm: true,

                isFeeCalculationHidden: true,
                hideMenu: true,
                partnerCustomerId: this.depositId,

                productsAvailed: 'BUY',
                exchangeScreenTitle: 'Buy Crypto Instantly',
                hostURL: window.location.origin,
                widgetHeight: '640px',
                widgetWidth: '400px',

            });
        },

        async transakManual() {
            Swal.fire({
                text: "The minimum order amount is USD 30.00 or equivalent.",
                icon: 'info',
            });
            return new transakSDK({
                apiKey: this.apiKey,  // Your API Key
                environment: this.environment, // STAGING/PRODUCTION
                themeColor: '2575fc', // App theme color

                // payments //
                cryptoCurrencyCode: this.depositDetail.token.symbol,
                network: this.networkList[(this.depositDetail.network).toLowerCase()],

                isFeeCalculationHidden: true,
                hideMenu: true,
                partnerCustomerId: this.depositId,

                productsAvailed: 'BUY',
                exchangeScreenTitle: 'Buy Crypto Instantly',
                hostURL: window.location.origin,
                widgetHeight: '640px',
                widgetWidth: '400px',
            });
        },

    }

}
</script>

<style>
@import '../css/styles.css';
#header {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-rows: 100px;
}

.header-items {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.deposit {
    color: #2E368F;
    height: 100%;
    overflow: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.deposit::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.deposit {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.btn-center{
    display: inline-block;
    width: 100%;
    font-weight: 500;
    color: var(--light);
    background-color: var(--info);
    border: 1px solid var(--gray);
    box-shadow: 0 3px 10px rgb(0 0 0 / 31%);
    border-radius: 20px;
    padding: 5px 12px;
}

.btn-center:hover{
    background-color: var(--primary);
    border: 1px solid var(--primary);
    box-shadow: 0 3px 10px rgb(0 0 0 / 51%);
    /* color: var(--secondary); */
}

.payment-box {
    display: flex;
    justify-content: space-evenly;
    padding: 20px 0;
    margin: 20px auto;
    min-width: 850px;
    width: 60%;
    border: 1px solid #303684;
    border-radius: 25px
}

.expired {
    border-color: #661212;
}

.img-box {
    display: flex;
    justify-content: center;
    margin: auto 0;
}

.deposit-id {
    font-weight: 600;
    color: #39B24B;
}

.address-web {
    display: flex;
    justify-content: center;
    width: 90%;
    margin: 0 auto;
}

.address-mobile {
    display: none;
}

/* .disclaimer-web {
    display: block;
}

.disclaimer-mobile {
    display: none;
} */

.payment-box-right {
    display: flex;
    flex-direction: column;
    margin: auto 0;
}

.address-box {
    display: flex;
    justify-content: center;
    padding: 10px 80px;
    font-size: 16px;
    font-weight: 300;
    background-color: #E9EAF2;
    border-radius: 25px;
    max-width: 100%;
    margin: 10px auto;
}

.deposit-text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 18px;
    line-height: 20px;
}

.expire-text {
    color: #BA3A26;
    margin-bottom: 10px;
}

.amount-text {
    font-size: 24px;
    font-weight: 500;
}

.symbol-text {
    font-size: 18px;
    font-weight: 400;
}

.network-text {
    font-size: 14px;
    font-weight: 300;
}

.countdown-text {
    width: 85%;
    font-size: 18px;
    text-align: center;
    margin: auto;
}

.countdown-timer {
    font-weight: 700;
}

.pending-box {
    display: flex;
    justify-content: center;
    font-size: 18px;
    padding: 10px 0;
    margin: 10px auto;
    font-weight: 500;
    color: #2E368F;
    background-color: #E9EAF2;
    border-radius: 25px;
    width: 450px;
    max-width: 90%;
}

.success-text {
    margin: auto;
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    color: #4CB050;
    padding-top: 20px;
    max-width: 330px;
}

.disclaimer-text {
    font-size: 12px;
    color: #BA3A26;
    margin: auto;
    width: 55%;
    text-align: justify;
    font-weight: 500;
    min-width: 750px;
}

.success-box {
    display: flex;
    justify-content: center;
    font-size: 18px;
    padding: 10px 0;
    margin: 10px auto;
    font-weight: 500;
    color: #2E368F;
    background-color: #E9EAF2;
    border-radius: 25px;
    width: 450px;
    max-width: 90%;
}

.success-box:hover {
    cursor: pointer;
    box-shadow: -1px 1px 20px -3px #2E368F inset;
    -webkit-box-shadow: -1px 1px 20px -3px #2E368F inset;
    -moz-box-shadow: -1px 1px 20px -3px #2E368F inset;
    /* background-color: #4CB050;
    color: white; */
}

@media only screen and (max-width:1025px) {
    .address-web {
        display: none;
    }

    .address-mobile {
        display: block;
        max-width: 90%;
        margin: auto;
    }

    .payment-box {
        flex-direction: column;
        padding: 10px 0;
        margin: 10px auto;
        min-width: 0;
        width: 90%;
    }

    .payment-box-right {
        padding-top: 0;
        max-height: fit-content;
    }

    .address-box{
        font-size: 12px;
    }

    /* .disclaimer-web {
        display: none;
    }

    .disclaimer-mobile {
        display: block;
    } */

    .disclaimer-text {
        width: 80%;
        min-width: 0;
    }
}
</style>